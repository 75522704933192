@import '~antd/dist/antd.css';
@import 'assets/weather/css/weather-icons.min.css';

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/montserrat-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat'), local('Montserrat'),
    url('assets/fonts/montserrat-v12-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/montserrat-v12-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('assets/fonts/montserrat-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('assets/fonts/montserrat-v12-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('assets/fonts/montserrat-v12-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.ant-input {
  font-family: 'Montserrat';
}

.ant-notification-notice.ant-notification-notice-closable.error {
  color: #ffffff;
  background-color: #ff3900;
}

.ant-notification-notice.ant-notification-notice-closable.success {
  color: #ffffff;
  background-color: #15c39a;
}

.ant-notification-notice.ant-notification-notice-closable.error
  .ant-notification-notice-message,
.ant-notification-notice.ant-notification-notice-closable.error .anticon-close,
.ant-notification-notice.ant-notification-notice-closable.success
  .ant-notification-notice-message,
.ant-notification-notice.ant-notification-notice-closable.success
  .anticon-close {
  color: #ffffff;
}
